import Axios from "axios";
import cookieCutter from "cookie-cutter";
import { handleExpiredAccessToken } from '@/lib/handleExpiredAccessToken';
import { handleExpiredRefreshToken } from '@/lib/handleExpiredRefreshToken';

const axios = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BACKEND_URL,
    headers: {
        "X-Requested-With": "XMLHttpRequest",
    },
    withCredentials: true,
});


axios.interceptors.request.use(
    (config) => {
        const token = cookieCutter.get("accessToken");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && error.response.data.message === "Expired token") {
            try {
                const newToken = await handleExpiredAccessToken();
                if (newToken) {
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axios(originalRequest);
                }
            } catch (error) {
                await handleExpiredRefreshToken();
                return Promise.reject(error);
            }
        }
        return Promise.reject(error);
    }
);

export default axios;
